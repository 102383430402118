export default () => ({
  isOpen: false,

  init() {
  },

  close() {
    this.isOpen = false;
  },

  toggle() {
    this.isOpen = !this.isOpen;
  },
});
