export default () => ({
  alternate_answers: [],

  init() {
    if (this.$refs.serverRenderedAlternateAnswers) {
      this.$nextTick(() => {
        console.log("Removing server rendered alternate answers");
        this.$refs.serverRenderedAlternateAnswers.remove();
      });
    }
  },

  deleteAlternateAnswer(index) {
    this.alternate_answers.splice(index, 1);
  }
});
